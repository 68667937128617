import {useState, useEffect} from "react";
import {axiosInstance} from "../../api/axiosInstances";
import useRefreshToken from "../../hooks/useRefreshToken";

const Users = () => {
    const [users, setUsers] = useState();
    const refresh = useRefreshToken();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getUsers = async () => {
            try {
                const response = await axiosInstance.get('/users', {
                    signal: controller.signal
                });

                console.log(response.data);
                isMounted && setUsers(response.data.users);

            } catch (err) {
                console.error(err);
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[]);

    return (
        <article>
            <h2>Users List</h2>
            {users?.length 
                ? (
                    <ul>
                        {users.map((user, i) => <li key={i}>{user?.email}</li>)}
                    </ul>
                ) : ( <p>No users to display.</p>)
            }
            <button onClick={() => refresh()}>Refresh</button>
        </article>
    );
}

export default Users;