import { useState, useRef, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { axiosInstance } from "../../api/axiosInstances"

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const errRef = useRef();

  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ errMsg, setErrMsg ] = useState("");
  
  useEffect(() => {
    emailRef.current.focus();
  },[]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    try {
      const response = await axiosInstance.post(  
        "/auth/signin", 
        JSON.stringify({email, password}), 
        {
          headers: {
            "Content-Type" : "application/json"
          },
          withCredentials: true
        }
      );
   
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      
      setAuth({email, role, accessToken})

      setEmail("");
      setPassword("");

      navigate(from, { replace: true });
    
    } catch (err) {
      console.error(err);
      if (!err?.response) {
        setErrMsg("No server response.")
      } else if (err.response?.status === 400) {
        setErrMsg("Missing email or password.");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Signin failed.");
      }
      errRef.current.focus();
    }
  }

  return (
    <>
      <section>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

        <h1>Sign In</h1>
        <form className="form" onSubmit={handleSubmit}>
          
          <label htmlFor="email">Email:</label>
          <input  
            type="text"
            id="email"
            ref={emailRef}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />

          <label htmlFor="password">Password:</label>
          <input  
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <button>Sign In</button>
        </form>
      </section>
    </>
  )
}

export default Login