import { axiosInstance } from "../api/axiosInstances";
import useAuth from "./useAuth";

const useRefreshToken =  () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axiosInstance.post('/auth/refresh-token',{}, {
            withCredentials: true
        });

        setAuth(prev => {
            console.log("prev: " + JSON.stringify(prev));
            console.log(response.data.accessToken);
            return { ...prev, accessToken: response.data.accessToken}
        });

        return response.data.accessToken;
    }

    return refresh;
};

export default useRefreshToken;