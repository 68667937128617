import axios from "axios";
import { BASE_URL } from "./constants";

const axiosAPI = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosInstance = axios.create({ baseURL: BASE_URL });
// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("accessToken");

//         if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (err) => {
//         return Promise.reject(err);
//     }
// );

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     async (error) => {
//         const originalRequest = error.config;

//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;

//             try {
//                 const response = await axiosInstance.post('/auth/refresh-token');

//                 localStorage.setItem("accessToken", response.data.accessToken);
//                 originalRequest.headers["Authorization"] = `Bearer ${response.data.accessToken}`;

//                 return axiosInstance(originalRequest);
//             } catch (innerError) {
//                 window.location.href = "/login";
//                 return Promise.reject(innerError);
//             }
//         }
//         return Promise.reject(error);
//     }
// );

export { axiosInstance, axiosAPI };
