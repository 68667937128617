import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Users from "./components/Users/Users";
import LinkPage from "./components/LinkPage/LinkPage";
import Admin from "./components/Admin/Admin"
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route path="/" element={<LinkPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/linkpage" element={<LinkPage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route element={<RequireAuth allowedRoles="admin" />} >
          <Route path="/home" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<Admin />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
